export default {
  vehicleServiceOrders: [],
  vehicleServiceOrder: {},
  vehicleServiceOrdersGrid: {
    data: [],
    total: 0
  }


}
