export default {
  // forSelect (state) {
  //   const vehicleServiceOrders = []
  //   for (const k in state.vehicleServiceOrders) {
  //     vehicleServiceOrders.push({
  //       label: state.vehicleServiceOrders[k].service_description,
  //       value: state.vehicleServiceOrders[k].id,
  //       data:  state.vehicleServiceOrders[k]
  //     })
  //   }
  //   return vehicleServiceOrders
  // }
}
