import state from './moduleVehicleServiceOrderState.js'
import mutations from './moduleVehicleServiceOrderMutations.js'
import actions from './moduleVehicleServiceOrderActions.js'
import getters from './moduleVehicleServiceOrderGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

