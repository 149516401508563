import Vue from 'vue'

export default {
  SET (state, payload) {
    state.vehicleServiceOrder = Object.assign({}, state.vehicleServiceOrder, payload) //precisa ser assim para manter a reatividade
  },
  REFRESH (state, payload) {
    state.vehicleServiceOrders = payload
  },
  SET_VEHICLE_SERVICE_ORDER_GRID (state, payload) {
    state.vehicleServiceOrdersGrid = payload
  },
  STORE (state, payload) {
    state.vehicleServiceOrders.push(payload) //precisa ser assim para manter a reatividade
    state.vehicleServiceOrders.sort((a, b) => { return new Date(a.created_at) - new Date(b.created_at) }) //ORDENA POR DATA

    state.vehicleServiceOrdersGrid.data.push(payload)
    state.vehicleServiceOrdersGrid.data.sort((a, b) => { return new Date(a.created_at) - new Date(b.created_at) }) //ORDENA POR DATA
    state.vehicleServiceOrdersGrid.total = state.vehicleServiceOrdersGrid.total + 1
  },
  UPDATE (state, payload) {
    const idx = state.vehicleServiceOrders.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.vehicleServiceOrders, idx, payload)

    const idx1 = state.vehicleServiceOrdersGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.vehicleServiceOrdersGrid.data, idx1, payload)
  },
  DELETE (state, payload) {
    const idx1 = state.vehicleServiceOrders.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.vehicleServiceOrders.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.vehicleServiceOrdersGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.vehicleServiceOrdersGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.vehicleServiceOrdersGrid.total = state.vehicleServiceOrdersGrid.total - 1
    }
  }

}
